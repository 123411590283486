import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

// import FranceComponent from "./FranceComponent";

export default function SouthKorea() {
  return (
    <>
      {/* hero section start */}
      <div id="#customcontainer" className="customcontainer mt-4 pt-3">
        <div
          className="bg-image d-flex justify-content-center mt-5 align-items-center"
          style={{
            backgroundImage: `url("/flags/south-korea.jpg")`,
            height: "500px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: "overlay",
          }}
        >
          <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
            <div className="col-md-8 ieltslandingcontent">
              <h1 className="bannerHeading text-light">
                Why Study In South Korea ?
              </h1>
              <ul className="bannerPoints text-dark">
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Renowned for its high-quality education
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Cutting-edge technology
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Affordable tuition, and scholarships
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> Offers rich cultural experiences and vibrant student life.
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> 13th Largest Economy.
                </li>
                <li className="my-3 ullist text-light">
                  <i className="fa fa-check-circle"></i> One of the Top 5 safest countries in the world.
                </li>
              </ul>
              <img
                src={"/flags/south-korea.png"}
                className="mt-2 mx-4"
                style={{ width: "80px" }}
              />
            </div>

            <div
              className="ieltslandingcontentmobile"
              style={{ display: "none" }}
            >
              <h1 className="bannerHeading text-light">
                Why to Study in South Korea ?
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* hero section ended */}

      <div className="container">
        <div className="section-head col-sm-12 mb-1 mt-3">
          <h4><span>South Korea</span></h4>
        </div>

        {/* Accordion */}
        <div className="accordion" id="accordionExample">

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Map Of South Korea
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body" style={{textAlign:"center"}}>
                <img src="/map/korea-map.png" className='img-fluid' alt="map" style={{width:"40%"}}/>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Why Study in South Korea?
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>South Korea has become a prominent destination for international students due to its excellent education system, vibrant culture, and advanced technology. Studying in South Korea offers numerous benefits, including access to globally recognized universities, affordable education, and opportunities for cultural immersion. Here are the top reasons to study in South Korea:</p>
                <ul>
                  <li style={{ listStyle: "circle" }}><strong>Globally Recognized Universities and Qualifications:</strong> Home to prestigious universities such as Seoul National University, KAIST, and Yonsei University.</li>
                  <li style={{ listStyle: "circle" }}><strong>Innovative and Advanced Education System: </strong> Focuses on research, innovation, and practical applications.</li>
                  <li style={{ listStyle: "circle" }}><strong>Affordable Tuition and Living Costs: </strong> Compared to other major study destinations, South Korea offers cost-effective options.</li>
                  <li style={{ listStyle: "circle" }}><strong>Scholarship Opportunities: </strong> Numerous government and university scholarships, such as the Korean Government Scholarship Program (KGSP).</li>
                  <li style={{ listStyle: "circle" }}><strong>Rich Cultural Heritage:</strong> A unique blend of traditional and modern culture provides an enriching experience for students.</li>
                </ul>
              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                General Weather Information
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>South Korea experiences four distinct seasons:</p>
                <ul>
                  <li style={{ listStyle: "circle" }}><strong>Spring (March-May): </strong> Mild weather with cherry blossoms. 5°C to 20°C (41°F to 68°F) </li>
                  <li style={{ listStyle: "circle" }}><strong>Summer (June-August): </strong> Hot and humid, with monsoon rains. 22°C to 30°C (72°F to 86°F)</li>
                  <li style={{ listStyle: "circle" }}><strong>Autumn (September-November): </strong> Cool and dry, with stunning fall foliage. 10°C to 25°C (50°F to 77°F) </li>
                  <li style={{ listStyle: "circle" }}><strong>Winter (December-February): </strong> Cold and snowy, especially in the northern regions. -10°C to 5°C (14°F to 41°F)</li>
                </ul>
              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                South Korea’s Education System
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h5>Overview of South Korea’s Education System: University and Graduate Schools</h5>
                <p>South Korea’s education system is known for its rigor and emphasis on academic excellence. After completing primary and secondary education (6-3-3-4 structure), students transition into higher education, which includes universities and graduate schools. Below is an elaborative look into the university and graduate school programs in South Korea, which can help guide international students seeking to study there.</p>

                <p>South Korea’s education system is structured into four distinct levels:</p>
                <ul>
                  <li ><strong>Kindergarten (3 years): </strong> For children aged 3-5, providing early childhood education through play-based activities.</li>
                  <li ><strong>Primary Education (6 years): </strong> For children aged 6-11, offering foundational academic skills.</li>
                  <li ><strong>Secondary Education (6 years): </strong> Includes: </li>
                  <ul>
                    <li style={{ listStyle: "circle" }}><strong>Middle School (3 years): </strong> For ages 12-14, focusing on a broad curriculum.</li>
                    <li style={{ listStyle: "circle" }}><strong>High School (3 years):</strong> For ages 15-17, offering general, vocational, or specialized tracks.</li>
                  </ul>

                  <li ><strong>Higher Education (2-6 years): </strong> Includes junior colleges, universities, and graduate schools.</li>
                </ul>
              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                University Programs in South Korea
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>Korean universities offer a wide array of programs at both undergraduate and graduate levels, attracting international students worldwide. Below are the main types of university programs available to international students:</p>

                <h5>1. Regular Semester Programs</h5>
                <ul>
                  <li style={{ listStyle: "circle" }}>These programs allow international students to study alongside domestic students during the regular spring and fall semesters.</li>
                  <li style={{ listStyle: "circle" }}>Students can enroll in general courses, which are offered in both Korean and foreign languages.</li>
                  <li style={{ listStyle: "circle" }}>After completing the required coursework, students are awarded a degree in their chosen field of study..</li>
                </ul>

                <h5>2. Exchange Student Program</h5>
                <ul>
                  <li style={{ listStyle: "circle" }}>Many Korean universities have agreements with universities abroad, creating exchange opportunities where students can study abroad for a specified period.</li>
                  <li style={{ listStyle: "circle" }}>Exchange students typically take courses alongside domestic students in their host university.</li>
                  <li style={{ listStyle: "circle" }}>Admission to the exchange program generally requires a certain level of academic performance and language proficiency.</li>
                </ul>

                <h5>3. Summer and Winter Semester Programs</h5>
                <ul>
                  <li style={{ listStyle: "circle" }}>International students can participate in special programs offered during the summer and winter breaks.</li>
                  <li style={{ listStyle: "circle" }}>These programs may include regular courses or specialized short-term courses such as Korean language training.</li>
                  <li style={{ listStyle: "circle" }}>After completing the required coursework, students are awarded a degree in their chosen field of study..</li>
                </ul>

                <h5>4. English-Taught Courses</h5>
                <ul>
                  <li style={{ listStyle: "circle" }}>Over the years, many Korean universities have been expanding their English-taught courses to cater to international students.</li>
                  <li style={{ listStyle: "circle" }}>Approximately 30% of all courses in Korean universities are now offered in English, and this number is higher at the graduate level.</li>
                  <li style={{ listStyle: "circle" }}>Some universities have international faculties where all courses are taught exclusively in English.</li>
                  <li style={{ listStyle: "circle" }}>This makes it easier for non-Korean speakers to pursue their studies in Korea without mastering the Korean language.</li>
                </ul>


                <h5>5. Korean Language Training Programs</h5>
                <ul>
                  <li style={{ listStyle: "circle" }}>Many Korean universities offer Korean language training programs for foreign students.</li>
                  <li style={{ listStyle: "circle" }}>Short-term programs, lasting 3-4 weeks, are designed to improve communication skills quickly, while long-term programs (10-40 weeks) focus on intensive language training with assignments related to real-life scenarios.</li>
                  <li style={{ listStyle: "circle" }}>In addition to language skills, cultural lessons are incorporated into the curriculum to help international students understand Korean society better and make their academic experience richer and more engaging.</li>
                </ul>


              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                Graduate School Programs in South Korea
              </button>
            </h2>
            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>Graduate education in South Korea is highly structured and offers students the opportunity to gain specialized knowledge and expertise in various fields. South Korea’s graduate schools can be categorized into general graduate schools and specialized graduate schools. Here is an overview of both:</p>

                <h5>Master's Program</h5>
                <ul>
                  <li >The typical duration for a master's program in South Korea is 2 years, and students are required to complete at least 24 credits.</li>
                  <li >Students must pass a comprehensive exam and submit a thesis, which will be evaluated by a committee of at least three examiners.</li>
                  <li >Some universities also offer specialized professional master’s degrees, such as in business or engineering.</li>
                </ul>

                <h5>Doctoral Program</h5>
                <ul>
                  <li >A doctoral program generally takes 3 years or more to complete.</li>
                  <li >Students must earn 36 credits, pass a comprehensive exam, and submit a doctoral thesis.</li>
                  <li >The doctoral thesis must be reviewed by a committee of at least five examiners.</li>
                  <li >South Korea has been investing heavily in doctoral research, and universities actively encourage research innovation through projects such as the BK21 project, which involves postdoctoral researchers.</li>
                </ul>

                <h5>Post-Doctoral Program</h5>
                <ul>
                  <li >After earning a doctoral degree, students can participate in post-doctoral research programs, where they work on specialized research topics</li>
                  <li >Many Korean universities are part of the BK21 project, which invites postdoctoral researchers to engage in advanced research and contribute to global scientific advancements.</li>
                </ul>

                <h5>Specialized Graduate Schools</h5>
                <ul>
                  <li >These schools are designed for students who wish to pursue professional careers and typically focus on applied skills, such as law, business, or public administration.</li>
                  <li >Specialized graduate schools may have different admission requirements, including work experience or practical training.</li>
                  <li >Some popular programs include those in engineering, business, and public health.</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                Student Life and International Community
              </button>
            </h2>
            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>Korean universities pride themselves on fostering a vibrant international community. The campus life for international students is enhanced through various student organizations, cultural events, and activities designed to help students connect and immerse themselves in Korean culture. Many universities also have strong alumni networks that offer ongoing support to graduates as they transition to the workforce.</p>

                <p>Furthermore, the Korean government has been actively encouraging international students to study in South Korea, offering scholarships, student visa programs, and financial aid opportunities to help reduce the cost of education and living expenses.</p>

                <ul>
                  <li ><strong>Cyber Universities:</strong> Offer online learning opportunities and flexibility for students unable to attend traditional classes.</li>
                  <li ><strong>Graduate Schools-Only Institutions:</strong >Focus solely on postgraduate education, emphasizing research and advanced studies.</li>
                </ul>

              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                Courses and Intakes
              </button>
            </h2>
            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h5>Popular Courses</h5>
                <ul>
                  <li ><strong>Business Administration</strong></li>
                  <li ><strong>Engineering (Robotics, AI, and Electronics)</strong></li>
                  <li ><strong>Korean Language and Cultural Studies</strong></li>
                  <li ><strong>Medicine and Biotechnology</strong></li>
                  <li ><strong>Computer Science and Information Technology</strong></li>
                </ul>

                <h5>Intakes</h5>
                <p>South Korean universities have two main intakes:</p>
                <ul>
                  <li ><strong>Spring Intake:</strong> March (Applications open September-November of the previous year).</li>
                  <li ><strong>Fall Intake:</strong> September (Applications open April-June).</li>
                </ul>
                <p>Korean universities have two main semesters, spring and fall, with the spring semester (admissions in March) typically accepting applications from September to November of the previous year and the fall semester (admissions in September) typically accepting applications from April to June of the same year. As the admission documents and application period may vary by university, prospective international students should precheck the specific deadlines for the university of their choice through the university's official website or other sources.</p>

              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                Application Process
              </button>
            </h2>
            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>When choosing a university, it is advisable to select a suitable university by collecting information through various channels and making a comprehensive judgment. (e.g., Searching for university names on portals to find related articles, reviewing promotional materials provided by universities, and exploring study abroad experiences shared by international students in online communities.) Korean universities offer a diverse range of academic programs, each with different duration of program. As there are variations in the academic program offered by each university, prospective international students should precheck the program of the university they wish to enroll in. </p>

                <div className="container mt-5">
                  <h2>Application Process</h2>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ACADEMIC PROGRAM</th>
                        <th>ELIGIBILITY</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <td>Associate degree</td>
                        <td>Individuals who completed the entire primary and secondary curriculums in the home country (12-year program). </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Bachelor’s Degree</td>
                        <td>Individuals who completed the entire primary and secondary curriculums in the home country (12-year program)</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Master’s Degree</td>
                        <td><strong>Holder of a Bachelor’s Degree</strong></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Doctoral Degree</td>
                        <td><strong>Holder of a Master’s Degree</strong></td>
                      </tr>
                    </tbody>
                  </Table>
                  <p><i>*The above-mentioned application eligibility is based on general standards, so it is advisable to precheck the specific admission guide of the desired university. </i></p>

                  <h5>Pre-Admission Stage</h5>
                  <ul>
                    <li><strong>Selecting a University and Department</strong>Choose a university and department that aligns with your academic interests. Utilize the Study in Korea website (https://www.studyinkorea.go.kr), university websites, or consult with a Korean diplomatic mission abroad for more information.</li>
                    <li><strong>Prepare Documents:</strong>Includes academic transcripts, language proficiency certificates (TOPIK or TOEFL/IELTS), financial proof, and personal statements.</li>
                    <li><strong>Submit the Admission Application Form</strong>Fill out and submit the admission application form along with the required documents.</li>
                    <li><strong>Obtain the Admission Letter</strong>After submission, the university will review your application and, if accepted, issue an admission letter.</li>
                  </ul>

                  <h5>Pre-Entry Stage</h5>
                  <ul>
                    <li><strong>Prepare Visa-Related Documents</strong>Inquire with a Korean diplomatic mission abroad or visit the immigration website (https://www.immigration.go.kr) for information on the visa application process.</li>
                    <li><strong>Apply for the Visa</strong>Submit the necessary documents to apply for a student visa.</li>
                    <li><strong>Obtain the Visa</strong>Once the visa is approved, you will receive your student visa to enter Korea.</li>
                  </ul>

                  <img style={{ textAlign: "center" }} src="/img/application_procedure.png" alt="procedure" />

                </div>
              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                Screening Methods
              </button>
            </h2>
            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li><strong>Application Process:- </strong>The online application method is widely used for undergraduate admissions. Undergraduate admissions are divided into two categories: freshman admissions (for high school graduates or above) and transfer admissions (for students who have studied at a university or hold a degree from a junior college or higher).</li>
                  <li><strong>Prepare Documents: </strong>Includes academic transcripts, language proficiency certificates (TOPIK or TOEFL/IELTS), financial proof, and personal statements.</li>
                  <li><strong>Selection Methods:- </strong>Most universities primarily select students through document screening, but some universities may also conduct interviews or exams. It’s advisable to check the screening methods of your desired university beforehand and prepare accordingly.</li>
                </ul>

                <h5>Required Documents for Application</h5>
                <ul>
                  <li>Visa issuance (recognition) application form</li>
                  <li>Educational institution business registration certificate</li>
                  <li>Standard admission letter</li>
                  <li>Proof document of the highest education level</li>
                  <li>Financial proof document</li>
                </ul>

                <h5>Additional Notes:</h5>
                <ul>
                  <li><strong>Application Period & Document Requirements </strong>The application period, documents to be submitted, and selection schedule may vary by each university or faculty. Refer to the specific admission guide of the institution you are applying to for detailed information.</li>
                  <li><strong>Document Verification </strong>Some documents, such as certificates of the highest education level, may require notarization or apostille confirmation.</li>
                </ul>
                <p>For further information on other universities and majors, visit the Study in Korea website <Link to={"https://www.studyinkorea.go.kr"} target="_none">(https://www.studyinkorea.go.kr)</Link> .</p>

                <h5>Undergraduate Education Tips</h5>
                <ul>
                  <li><strong>Course Registration </strong>Enroll in courses for the upcoming semester before each semester begins to ensure your schedule aligns with your academic plan.</li>
                  <li><strong>Course Evaluation </strong>Assess and provide feedback on professors' lectures at the end of each semester to contribute to the improvement of the academic experience.</li>
                  <li><strong>Leave of Absence </strong>If you need a break from your studies, apply for a leave of absence. For foreign students, you are required to leave the country within 15 days from the approved date.</li>
                  <li><strong>Return from Leave </strong>If you have been on leave or suspension, apply for permission to return to school during or after your leave period.</li>
                  <li><strong>Academic Warning </strong>A warning is issued if your Grade Point Average (GPA) falls below a certain threshold, which varies by school (e.g., 1.5–2.0 out of 4.5). Accumulating three warnings may lead to expulsion and can affect your visa extension.</li>
                  <li><strong>Change of Major </strong>If you're interested in changing your field of study, obtain permission from the school to officially change your major.</li>
                  <li><strong>Double Major/Minor </strong>Apply for a double major or minor to complete additional courses outside of your main discipline, fulfilling the major requirements for both departments. This is generally reflected on your degree at the time of conferment.</li>
                  <li><strong>English-Taught Courses </strong>Many universities offer courses where lectures, exams, etc., are conducted entirely in English. You can search the list of universities offering English-taught courses provided by ETS for more information.</li>
                  <li><strong>Expulsion </strong>Expulsion results from various academic or behavioral circumstances and results in losing student status at the university.</li>
                  <li><strong>Voluntary Withdrawal </strong>If you decide to leave the university based on personal reasons, you can voluntarily withdraw from the institution.</li>
                </ul>

                <h4>Language Education</h4>
                <img src="/img/language-korean.png" />
                <h6>Importance of Korean Language Proficiency</h6>
                <p>Proficiency in Korean is crucial for academic success and daily life in South Korea. Most university programs require students to demonstrate language skills, either through Korean or English proficiency tests.</p>

                <h4>Learning Opportunities</h4>
                <ul>
                  <li><strong>University-Affiliated Language Institutes: </strong>Provide comprehensive courses in speaking, listening, reading, and writing, often integrated with cultural experiences.</li>
                  <li><strong>Free Korean Language Courses: </strong>Offered by local government support centers and NGOs, such as the Multicultural Family Support Portal 'Danuri.'</li>
                  <li><strong>TOPIK (Test of Proficiency in Korean): </strong></li>
                  <ul>
                    <li style={{ listStyle: "circle" }}>Administered six times a year.</li>
                    <li style={{ listStyle: "circle" }}>Divided into TOPIK I (levels 1-2 for beginners) and TOPIK II (levels 3-6 for advanced learners).</li>
                    <li style={{ listStyle: "circle" }}>Required for admission (level 3 or above) and graduation (level 4 or above) in Korean-taught programs.</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                Detailed Expenses for Studying in South Korea (In INR)
              </button>
            </h2>
            <div id="collapseEighteen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>Studying in South Korea offers an enriching academic experience, but it’s important for prospective students to plan their finances effectively. Below is a comprehensive breakdown of the tuition fees and living costs, converted to Indian Rupees (INR), to guide students in preparing for their financial commitments when studying in South Korea.</p>

                <h5>Tuition Fees for Academic Programs (in INR)</h5>
                <p>Tuition fees in South Korea depend on the type of academic program, the university (national or private), and the course of study. Typically, national universities, which receive government support, have lower tuition fees compared to private universities.</p>


                <ul>
                  <li><strong>Associate Degree: </strong>₹1,77,000 to ₹2,36,000 per semester (approx. ₹1,77,000 to ₹2,36,000).</li>
                  <li><strong>Bachelor's Degree: </strong>₹2,95,000 to ₹4,13,000 per semester (approx. ₹2,95,000 to ₹4,13,000).</li>
                  <li><strong>Master's Degree: </strong>₹3,54,000 to ₹4,72,000 per semester (approx. ₹3,54,000 to ₹4,72,000).</li>
                  <li><strong>Doctoral Degree: </strong>₹4,13,000 to ₹5,72,000 per semester (approx. ₹4,13,000 to ₹5,72,000).</li>
                </ul>

              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                Living Expenses in Korea
              </button>
            </h2>
            <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>Here is a summary of the common monthly expenses you should plan for, along with their estimated costs:</p>

                <div className="container mt-5">
                  {/* <h2>User Table</h2> */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Estimated Cost (KRW)</th>
                        <th>Estimated Cost (INR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <td>Housing (Dormitory)</td>
                        <td>₩500,000 to ₩700,000</td>
                        <td>₹30,800 to ₹43,000</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Meals</td>
                        <td>₩200,000 to ₩300,000</td>
                        <td>₹12,300 to ₹18,400</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Transportation</td>
                        <td>₩50,000 to ₩100,000</td>
                        <td>₹3,100 to ₹6,200</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Medical Insurance (NHI)</td>
                        <td>₩40,000</td>
                        <td>₹2,400</td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Miscellaneous Expenses</td>
                        <td>₩100,000 to ₩200,000</td>
                        <td>₹6,200 to ₹12,300</td>
                      </tr>
                    </tbody>
                  </Table>
                  <p><i>Note: All values are approximate, and actual costs may vary based on location, lifestyle, and university policies.</i></p>
                </div>

              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                Healthcare and Medical Insurance
              </button>
            </h2>
            <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h4>1. National Health Insurance (NHI)</h4>
                <ul>
                  <li><strong>Description: </strong>Enrollment in the National Health Insurance (NHI) program is mandatory for all international students in South Korea. The NHI offers comprehensive healthcare coverage, including medical checkups, doctor visits, hospital stays, and emergency care.</li>
                  <li><strong>Cost:</strong> ₩40,000 per month (approx. ₹2,400).</li>
                </ul>
                <p><i> Note: Students on a student visa can receive discounts on their insurance premiums. The cost may vary depending on the insurance provider and the student's university</i></p>

                <h5>2. Daily Life Tips for International Students
                </h5>
                <p>Living in South Korea can be an exciting and unique experience. Here are some useful tips to help international students adjust to daily life and make the most of their time in Korea:</p>
                <ul>
                  <li><strong>Communication Apps:</strong></li>
                  <ul>
                    <li><strong>KakaoTalk: </strong>This is the most popular messaging app in South Korea, widely used for communication, making calls, and even financial transactions. It's essential for staying connected with friends, family, and colleagues.</li>
                    <li><strong>Wolse (Monthly Rent): </strong>A more conventional rental system where tenants pay a fixed monthly rent, typically covering utilities and maintenance costs.</li>
                    <li><strong>Line and Naver: </strong>These apps are also commonly used for messaging and social networking.</li>
                    <ul>
                      <li><strong>Jeonse: </strong>₩10,000,000 to ₩50,000,000 (approx. ₹612,000 to ₹3,060,000) as a lump-sum deposit.</li>
                      <li><strong>Wolse: </strong>₩500,000 to ₩1,000,000 per month (approx. ₹30,800 to ₹61,500).
                      </li>
                    </ul>
                  </ul>
                  <p><i>Note: Monthly rent for Wolse can vary based on location, size of the apartment, and amenities provided.</i></p>

                  <li><strong>Navigation and Transportation:</strong></li>
                  <ul>
                    <li><strong>Naver Map: </strong>While Google Maps is available, Naver Map is the most accurate and widely used navigation app in South Korea. It helps with finding locations, directions, and public transportation routes.</li>
                    <li><strong>Public Transport: </strong>South Korea has an efficient and affordable public transport system that includes buses, subways, and trains. Most cities, including Seoul, Busan, and Incheon, are well connected through the public transport network.</li>
                    <li><strong>Transportation Costs:</strong> ₩50,000 to ₩100,000 per month (approx. ₹3,100 to ₹6,200), depending on the frequency of travel.</li>
                  </ul>
                </ul>


                <h5>3. Recycling and Environmental Practices:
                </h5>
                <ul>
                  <li>South Korea has a strict and organized recycling system. International students should familiarize themselves with the waste segregation process and separate recyclables from general waste to comply with local regulations.</li>

                  <li>Waste Disposal: Generally, waste is divided into categories like recyclables, food waste, and non-recyclables. Fines may be imposed for improper waste disposal.</li>
                </ul>

              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                Scholarships in South Korea for International Students
              </button>
            </h2>
            <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">

                <div className="container mt-5">
                  <h2>Scholarships in South Korea for International Students</h2>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th><strong>Institution Name</strong></th>
                        <th><strong>Scholarship Name </strong></th>
                        <th><strong>Website</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <td>Gasan Cutural Foundation </td>
                        <td>Gasan Global Scholarship</td>
                        <td><a href="https://www.gasan.com/our-foundation/">https://www.gasan.com/our-foundation/</a></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Daewoong Foundation</td>
                        <td>Global Schoalrship</td>
                        <td><Link to={"http://daewoongfoundation.or.kr "}>http://daewoongfoundation.or.kr </Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Rotary Club</td>
                        <td>Scholarship for international students </td>
                        <td><Link to={"http://www.rotarykorea.org"}>http://www.rotarykorea.org</Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Samsung Dream Scholarship Foundation</td>
                        <td>Global Hope Scholarship</td>
                        <td><Link to={"http://www.sdream.or.kr"}>http://www.sdream.or.kr</Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Pencil Scholarship Foundation</td>
                        <td>Pencil Scholarship Foundation Student</td>
                        <td><Link to={"http://www.pensaf.or.kr "}>http://www.pensaf.or.kr </Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Woo Jung Education & Culture Foundation </td>
                        <td>Scholarship Program</td>
                        <td><Link to={"http://www.woojungedu.co.kr/edu r "}>http://www.woojungedu.co.kr/edu  </Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Lee & Won Foundation</td>
                        <td>Asian Fellowship</td>
                        <td><Link to={"http://www.leewonfoundation.org  "}>http://www.leewonfoundation.org  </Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>POSCO TJ Park Foundation </td>
                        <td>POSCO Asia Fellowship</td>
                        <td><Link to={"https://www.postf.org"}>https://www.postf.org</Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Hansae Yes24 Foundation </td>
                        <td>Foreign Students Scholarship</td>
                        <td><Link to={"http://www.hansaeyes24foundation.com"}>http://www.hansaeyes24foundation.com</Link></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr >
                        <td>Hyundai Motor Chung Mong- Koo Foundation  </td>
                        <td>Hyundai Motor Chung Mong-Koo Scholarship </td>
                        <td><Link to={"http://www.hyundai-cmkfoundation.org"}>http://www.hyundai-cmkfoundation.org</Link></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <p>Studying in South Korea offers various scholarship opportunities to international students, ranging from government-funded programs to university-specific awards and other privately funded scholarships. These financial aids significantly ease the burden of tuition fees, living expenses, and other study-related costs. Here’s an elaborated guide to scholarships available for international students in South Korea:</p>


                <h4>1. Korean Government Scholarship Program (KGSP)</h4>
                <p>The Korean Government Scholarship Program (KGSP), also known as the Global Korea Scholarship (GKS), is one of the most prestigious scholarships for international students. Administered by South Korea’s Ministry of Education, it covers a wide range of fields and supports undergraduate, graduate, and research programs.</p>

                <h4>The Korean Government Scholarship Program (KGSP), also known as the Global Korea Scholarship (GKS), is one of the most prestigious scholarships for international students. Administered by South Korea’s Ministry of Education, it covers a wide range of fields and supports undergraduate, graduate, and research programs.</h4>
                <ul>
                  <li><strong>Full tuition fees </strong>for the entire duration of the program.</li>
                  <li><strong>One-year Korean language training </strong>before the academic program begins.</li>
                  <li><strong>Round-trip airfare </strong>between the student’s home country and South Korea.</li>
                  <li><strong>Monthly living stipend </strong> (varies by program level but typically covers living expenses).</li>
                  <li><strong>Settlement allowance </strong> upon arrival in South Korea.</li>
                  <li><strong>Health insurance </strong>for the duration of the stay.</li>
                  <li><strong>Research costs</strong>and thesis printing expenses (for graduate and research scholars).</li>
                </ul>

                <h5>Eligibility:</h5>

                <ul>
                  <li>Applicants must be citizens of a country with diplomatic relations with South Korea.</li>
                  <li>Applicants must meet academic and language proficiency requirements.</li>
                  <li>Specific age limits apply (e.g., under 25 years for undergraduate, under 40 years for doctoral programs).</li>
                </ul>

                <h5>Application Process:</h5>
                <ul>
                  <li>Applications are processed through either the Korean Embassy or directly through South Korean universities.</li>
                  <li>The selection involves multiple stages, including document screening, interviews, and tests.</li>
                </ul>

                <h5>1. Commitments:</h5>
                <ul>
                  <li>Scholars must maintain a specific GPA and academic performance throughout their program.</li>
                  <li>Scholars are encouraged to contribute to South Korea’s global reputation.</li>
                </ul>


                <h5>2. University-Specific Scholarships</h5>
                <ul>
                  <li>Many South Korean universities offer university-specific scholarships aimed at attracting talented international students. These scholarships can cover partial to full tuition fees, living expenses, or both.</li>
                </ul>

                <h5>3. Types of University Scholarships:</h5>
                <ul>
                  <li>Merit-Based Scholarships: Awarded to students with excellent academic performance. These can cover from partial to full tuition and may be renewable based on academic progress.</li>
                  <li>Need-Based Scholarships: Designed for students who demonstrate financial need. These often come with living stipends, book allowances, and other forms of financial support.</li>
                  <li>Language Proficiency Scholarships: Awarded to students with high scores in Korean lanuage proficiency tests (such as TOPIK) or English proficiency tests like TOEFL or IELTS.</li>
                  <li>Research and Graduate Assistantships: Graduate students may receive financial aid by serving as research or teaching assistants, covering tuition or offering stipends.</li>
                </ul>
                <p>University-specific scholarships can range from 30% to 100% of tuition fees depending on the university's scholarship program. For further information, visit the Study in Korea website or the individual university websites.</p>

                <h5>4. Other Scholarships in South Korea</h5>
                <ul>
                  <li>Gasan Cultural Foundation - Gasan Global Scholarship</li>
                  <li>Daewoong Foundation - Global Scholarship </li>
                  <li>Rotary Club – Scholarship for International Students</li>
                  <li>Samsung Dream Scholarship Foundation - Global Hope Scholarship</li>
                  <li>Pencil Scholarship Foundation</li>
                  <li>Woo Jung Education & Culture Foundation – Scholarship Program</li>
                  <li>POSCO TJ Park Foundation - POSCO Asia Fellowship</li>
                  <li>Hyundai Motor Chung Mong-Koo Foundation – Hyundai Motor Chung Mong-Koo Scholarship</li>
                </ul>


                <h5>5. Additional Opportunities:</h5>
                <ul>
                  <li>Global Korea Scholarship (GKS) Non-degree Program for Foreign Exchange Students: Offers funding for foreign exchange students, including academic encouragement grants, settlement funds, and round-trip airfare.</li>
                  <li>The Korean Foundation for International Culture (KF) also offers several fellowships and grants for research, studies, and cultural exchange programs</li>
                </ul>

              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                Employment Opportunities for International Students in South Korea
              </button>
            </h2>
            <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h4>Part-Time Work</h4>
                <p>International students holding a D-2 visa in South Korea are allowed to work part-time during their studies. Here are the key details:</p>
                <h5>Working Hours:</h5>
                <ul>
                  <li>Up to 20 hours per week during the semester.</li>
                  <li>Full-time (40 hours per week) during academic breaks (summer and winter).</li>
                </ul>

                <h4>Typical Part-Time Jobs for Students:</h4>
                <ul>
                  <li><strong>Tutoring:</strong>Many students find tutoring jobs, teaching English or other subjects to Korean students.</li>
                  <li><strong>Retail Jobs:</strong>Retail stores, cafes, restaurants, and convenience stores hire students for part-time roles.</li>
                  <li><strong>Internships:</strong>Some academic programs may offer internships that can be considered as part-time work.</li>
                  <li><strong>Other Jobs:</strong>Depending on your language proficiency and skills, you might also find part-time opportunities in tech, marketing, or other specialized fields.</li>
                </ul>

                <h4>Post-Graduation Employment Options</h4>
                <p>South Korea offers post-graduation employment visas for international students. Here are the primary visa types:</p>

                <ul>
                  <li><strong>Job-Seeking Visa (D-10):</strong></li>
                  <ul>
                    <li>After graduation, international students can apply for a D-10 visa to look for a job in South Korea.</li>
                    <li>The D-10 visa allows graduates up to two years to find employment related to their major field of study.</li>
                    <li>Once a student secures a job, they can apply for an employment visa.</li>
                  </ul>
                  <li><strong>Employment Visa (E-Series):</strong></li>
                  <ul>
                    <li>The E-Series visas are categorized based on the industry, and they are issued to professionals who secure a job offer in South Korea.</li>
                    <li>Common E-Series visas include: E-2 (Teaching English), E-3 (Research), E-4 (Technology), E-5 (Professional Work), E-7 (Specialized Work)</li>
                  </ul>
                  <li><strong>Startup Visa:</strong></li>
                  <ul>
                    <li>South Korea also provides a Startup Visa (D-8) for graduates who wish to start their own businesses. To qualify, the business must meet certain criteria set by the government</li>
                  </ul>
                  <li><strong>Websites for Job Search:</strong></li>
                  <ul>
                    <li>LetUln (https://www.letuln.com): A platform that helps students find part-time jobs, internships, and full-time employment.</li>
                    <li>SpecUp (https://www.specup.co.kr): A career portal that specializes in job listings, especially for students with high academic and technical qualifications.</li>
                  </ul>
                  <li><strong>Annual Job Fairs:</strong></li>
                  <ul>
                    <li>National Institute for International Education (NIIED) and KOTRA (Korea Trade-Investment Promotion Agency) organize job fairs where students can meet potential employers and apply for positions. These events are great networking opportunities.</li>
                  </ul>
                </ul>

              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                Practical Information for International Students in South Korea
              </button>
            </h2>
            <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li><strong>Opening a Bank Account:</strong></li>
                  <ul>
                    <li>You will need to present a valid passport, student ID, and proof of residence (a lease agreement or dormitory confirmation) to open a bank account in South Korea.</li>
                  </ul>
                  <li><strong>Employment Visa (E-Series):</strong></li>
                  <ul>
                    <li>The E-Series visas are categorized based on the industry, and they are issued to professionals who secure a job offer in South Korea.</li>
                    <li>Common E-Series visas include: E-2 (Teaching English), E-3 (Research), E-4 (Technology), E-5 (Professional Work), E-7 (Specialized Work)</li>
                    <li></li>
                  </ul>
                  <li><strong>International Remittance:</strong></li>
                  <ul>
                    <li>South Korean banks allow students to send money internationally. The annual limit for remittances is USD 100,000 without requiring additional documentation.</li>
                    <li>Most banks offer services in English, and transferring money online through mobile banking is widely available.</li>
                  </ul>
                  <li><strong>Mobile Phone Activation</strong></li>
                  <ul>
                    <li>To activate a mobile phone, students will need to provide:</li>
                    <li>Proof of Identity: Passport or alien registration card.</li>
                    <li>Proof of Residence: A utility bill or lease agreement.</li>
                    <li>Mobile providers like SK Telecom, KT, and LG U+ offer student-friendly plans with affordable data, text, and calling options.</li>
                  </ul>
                </ul>

                <h5>Waste Disposal and Recycling</h5>
                <ul>
                  <li>Waste Disposal in South Korea:</li>
                  <ul>
                    <li>South Korea has an efficient and strict waste disposal system, and students are encouraged to follow the waste separation guidelines.</li>
                    <li>Types of Waste: Paper, plastic, glass, food waste, and general waste are separated. Food waste must be disposed of in designated bins, and there is often a fee for food waste disposal.</li>
                  </ul>
                  <li>Apps for Waste Collection Schedules:</li>
                  <ul>
                    <li>You can download mobile apps like Recycall or use your local district’s app to stay informed about waste collection days and ensure proper disposal.</li>
                  </ul>
                </ul>

                <h5>Safety Guides and Resources</h5>
                <ul>
                  <li>Safety Guides: The National Police Agency provides safety guides and emergency information in multiple languages, accessible via KakaoTalk (South Korea’s most popular messaging app) and other government portals.</li>
                  <li>KakaoTalk Emergency Resources: Many safety-related notifications, crime alerts, and emergency information can be found on the KakaoTalk Safety Center.</li>
                </ul>

                <h5>Emergency Medical Services</h5>
                <ul>
                  <li>E-GEN Portal: Provides real-time information on available medical facilities and emergency services in South Korea. It’s accessible online at www.e-gen.or.kr.</li>
                  <li>Emergency Medical Information Service App: This app offers information about nearby hospitals, pharmacies, and available medical services based on your location.</li>
                </ul>
                <p>By utilizing these resources and following the guidelines, international students can ensure a smooth and safe experience while studying in South Korea.</p>

              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                Additional Resources for International Students in South Korea
              </button>
            </h2>
            <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h4><Link to={"www.studyinkorea.go.kr"} target="_none">Study in Korea Website</Link></h4>
                <ul>
                  <li>Study in Korea Website</li>
                  <ul>
                    <li>Website: <Link to="www.studyinkorea.go.kr" target="_none">www.studyinkorea.go.kr</Link></li>
                    <li>Overview: The official government platform for international students in South Korea, offering comprehensive information on scholarships, visa processes, admission procedures, and various student services. It’s a must-visit resource for any student planning to study in Korea.</li>
                  </ul>
                </ul>

                <h5>Job Fairs and Internships</h5>
                <ul>
                  <li>National Institute for International Education (NIIED) & KOTRA</li>
                  <ul>
                    <li>Overview: These organizations frequently organize job fairs and internships that connect international students with South Korean companies. It's an excellent opportunity to network, explore career options, and find employment opportunities in South Korea. Make sure to stay updated on the schedules for these events by visiting their websites and registering in advance.</li>
                    <li>NIIED Website: www.niied.go.kr</li>
                    <li>KOTRA Website: www.kotra.or.kr</li>
                  </ul>
                </ul>

                <h5>Real Estate and Housing Apps</h5>
                <ul>
                  <li>Zigbang</li>
                  <ul>
                    <li>Overview: One of the most popular apps for finding rental properties in South Korea. It allows you to browse listings based on your preferences and location.</li>
                    <li><strong>Website:- </strong><Link to="https://www.zigbang.com">https://www.zigbang.com</Link></li>
                  </ul>
                  <li>Dabang</li>
                  <ul>
                    <li>Overview: Similar to Zigbang, Dabang offers a comprehensive range of rental properties. It’s a great resource for finding both short-term and long-term accommodations.</li>
                    <li><strong>Website:- </strong><Link to={"https://www.dabangapp.com"}>https://www.dabangapp.com</Link></li>
                  </ul>

                  <li>Naver Real Estate
                    <ul>
                      <li>Overview: Naver, South Korea's largest search engine, offers a robust real estate platform where you can search for apartments and other rental properties in various regions across the country.</li>
                      <li><strong>Website:- </strong><Link to={"https://land.naver.com/"}>https://land.naver.com/</Link></li>
                    </ul>
                  </li>
                </ul>

                <h5>Communication & Navigation Apps</h5>
                <ul>
                  <li>KakaoTalk</li>
                  <ul>
                    <li>Overview: KakaoTalk is the dominant messaging app in South Korea, widely used for both personal and professional communication. Many services and emergency notifications are shared via KakaoTalk, so it’s essential to download this app.</li>
                    <li><strong>Website:- </strong><Link to={"https://www.kakaocorp.com/"}>https://www.kakaocorp.com/</Link></li>
                  </ul>
                  <li>Naver Map & Kakao Map</li>
                  <ul>
                    <li>Overview: These navigation apps provide accurate, real-time mapping and directions, making it easy to navigate the streets of South Korea, whether you're walking, driving, or using public transport.</li>
                    <li><strong>Never Map Website:- </strong><Link to={"https://map.naver.com/p/"}>https://map.naver.com/p/</Link></li>
                    <li><strong>kakao Map Website:- </strong><Link to={"https://map.kakao.com/"}>https://map.kakao.com/</Link></li>
                  </ul>
                </ul>

                <h5>Translation & Language Support</h5>
                <ul>
                  <li>Papago</li>
                  <ul>
                    <li>Overview: Papago is a powerful translation app by Naver that supports multiple languages, including English, Korean, Chinese, Japanese, and more. It’s a helpful tool for translating conversations, signs, and documents while in South Korea.</li>
                    <li><strong>Website:- </strong><Link to={"https://papago.naver.com/"}>https://papago.naver.com/</Link></li>
                  </ul>
                </ul>

                <h5>Food Delivery Apps</h5>
                <ul>
                  <li>Baemin (Baedal Minjok)</li>
                  <ul>
                    <li>Overview: One of the most popular food delivery services in South Korea. Baemin offers a wide selection of restaurants and food options, delivering food right to your doorstep.</li>
                    <li><strong>Website:- </strong><Link to={"https://baemin.com/"}>https://baemin.com/</Link></li>
                  </ul>
                  <li>Yogiyo</li>
                  <ul>
                    <li>Overview: Another popular food delivery app, similar to Baemin, Yogiyo is a go-to choice for international students craving familiar meals or exploring Korean cuisine.</li>
                    <li><strong>Website:- </strong><Link to={"https://yogiyo.co/"}>https://yogiyo.co/</Link></li>
                  </ul>
                </ul>


              </div>
            </div>
          </div>


          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                Emergency Helplines & Government Support
              </button>
            </h2>
            <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Emergency Helplines:</li>
                  <ul>
                    <li>Police (112): For emergencies, dial 112 for police assistance</li>
                    <li>Fire/Ambulance (119): Dial 119 for fire or ambulance emergencies.</li>
                    <li>Women’s Emergency Counseling (1366): Provides immediate support to women in crisis situations.</li>
                    <li>Multilingual Assistance (1345): Offers 24/7 multilingual support for foreigners in South Korea.</li>
                  </ul>
                  <li>Government Support Centers:</li>
                  <ul>
                    <li>South Korea has a structured government support system in place to ensure that international students can access emergency services, legal assistance, and general support in multiple languages. You can contact 1345 for information and support services available to international residents.</li>
                  </ul>
                </ul>

                <h5>Additional Student Services</h5>
                <ul>
                  <li>International Student Associations: Many universities in South Korea have student associations specifically for international students. These associations can help with adjusting to life in Korea, provide cultural exchange opportunities, and assist with finding housing or jobs.</li>
                  <li>Korean Language Classes:</li>
                  <ul>
                    <li>While many people in South Korea speak English, learning Korean can greatly enhance your experience. Many universities offer language exchange programs and Korean language courses for international students.</li>
                  </ul>
                </ul>
                <p>By utilizing these resources, international students can have a more informed and smooth experience while studying in South Korea, making it easier to navigate everyday life, find job opportunities, and ensure safety.</p>


              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}




